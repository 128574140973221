const TOGGLE_BUTTON_ID = 'toggle-password';
const PASSWORD_INPUT_ID = 'password-input';

document.addEventListener('DOMContentLoaded',  () => {
const toggleButtonElement = document.getElementById(TOGGLE_BUTTON_ID);
  if (toggleButtonElement !== null) {
    toggleButtonElement.addEventListener('click', onClickToggle);
  }
});

const onClickToggle = function () {
  const passwordShowToggleButton = document.getElementById(TOGGLE_BUTTON_ID);
  const passwordInput = document.getElementById(PASSWORD_INPUT_ID)
  if (passwordShowToggleButton.classList.contains("hidden-password")) {
    passwordShowToggleButton.classList.remove("hidden-password");
    passwordShowToggleButton.classList.add("show-password");
    passwordInput.type = "text";
  } else {
    passwordShowToggleButton.classList.remove("show-password");
    passwordShowToggleButton.classList.add("hidden-password");
    passwordInput.type = "password"
  }
}

