/*
 * Find elements that needs to be tracked
 */
document.addEventListener('DOMContentLoaded', function () {
  document.querySelectorAll('[data-iwsignin-track]').forEach(el => {
    try {
      const tracking = JSON.parse(el.dataset.iwsigninTrack);
      el.addEventListener('click', () => {
        track(tracking);
      });
    } catch (error) {
      console.warn(`init tracking failed on element:${el} error:${error}`);
    }
  });
});

const track = ({ event_category, event_action, event_label }) => {
  const { utag } = window;

  const trackData = { event_category, event_action, event_label };
  if (typeof (utag) !== 'undefined') {
    utag.link(trackData);
  }
};
