const LOGIN_FORM = '#loginForm';


document.addEventListener('DOMContentLoaded', function () {
  // disable double click after submit
  if (document.querySelector(LOGIN_FORM) !== null) {
    document.querySelector(LOGIN_FORM).addEventListener('submit', function (form) {
      form.submitter.disabled = true
    })
  }
});
