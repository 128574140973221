function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
}

document.addEventListener('DOMContentLoaded', function () {
  // https://javascript.info/cookie forwards this cookie only if coming from the same site
  const token = uuidv4()
  document.cookie = "CSRF-TOKEN=" + token;
  [...document.getElementsByClassName('csrf-token')].forEach(element => element.value = token);
});
