const FIELD_DATE = 'input[name="date"]';
const FIELD_HEIGHT = 'input[name="height"]';
const FIELD_WIDTH = 'input[name="width"]';
const FIELD_PLATFORM = 'input[name="platform"]';
const BX_URL = '/profile';
const BASE_DOMAIN = /immowelt.(de|at|ch)$/i;
const BX_EXPIRES = 60 * 60 * 24 * 365 * 2;

const setFieldValue = (selector, value) => {
  document.querySelector(selector).value = value;
};

const bxCookieAbsent = function () {
  return document.cookie.indexOf('bx') === -1;
};

const fetchCookie = function (profileData, cb) {
  let xhr = new XMLHttpRequest();
  xhr.open('POST', BX_URL, true);
  xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
  xhr.setRequestHeader('X-CSRF-TOKEN', fetchCookieByName('CSRF-TOKEN'));
  xhr.send(JSON.stringify(profileData));
  xhr.onreadystatechange = function () {
    if (this.readyState === XMLHttpRequest.DONE && this.status === 201) {
      cb(xhr.responseText);
    }
  };
};

const fetchCookieByName = function getCookie(cookieName) {
  let cookie = {};
  document.cookie.split(';').forEach(function(el) {
    let [key,value] = el.split('=');
    cookie[key.trim()] = value;
  })
  return cookie[cookieName];
}

const addBxCookie = function (profileData) {
  const match = BASE_DOMAIN.exec(location.hostname);

  if (!match) {
    return;
  }

  fetchCookie(profileData, function (result) {
    document.cookie = `bx=${result};path=/;domain=${match[0]};max-age=${BX_EXPIRES};secure`;
  });
};

document.addEventListener('DOMContentLoaded', function () {

  const date = new Date().toLocaleString();

  setFieldValue(FIELD_DATE, date);
  setFieldValue(FIELD_HEIGHT, screen.height);
  setFieldValue(FIELD_WIDTH, screen.width);
  setFieldValue(FIELD_PLATFORM, window.navigator.platform);

  if (bxCookieAbsent()) {
    addBxCookie({
      date: date,
      height: screen.height,
      width: screen.width,
      platform: window.navigator.platform
    });
  }
}, false);

